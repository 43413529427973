import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useParams } from 'react-router-dom';
import Web3 from 'web3';
import TestToken from '../../src/abis/Nft.json';
import TokenStaking from '../../src/abis/TokenStaking.json';
import {fetchRows,fetchOrders} from "../actions/general";
import axios from "axios";
import {WEB_CONFIG} from '../config/Static'
import Header from '../components/Header';
import _ from "lodash";
import NftStakebox from "../components/NftStakebox";
const Nftpools = () => {
    const dispatch = useDispatch();
    const { market } = useParams();
    const [account, setAccount] = useState(null);
    const [pool, setPool] = useState({});
    const [stakeresults, setStakeresults] = useState({});
    const [mystakes, setMystakes] = useState({});
    const [mytoplam, setMytoplam] = useState({});
    const [mytoplams, setMytoplams] = useState({});
    const [network, setNetwork] = useState({ id: '0', name: 'none' });
    const [testTokenContract, setTestTokenContract] = useState('');
    const [tokenStakingContract, setTokenStakingContract] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [appStatus, setAppStatus] = useState(true);
    const [loader, setLoader] = useState(false);
    const marketurl = market?{url:market}: {};
    async function updatedata(hesap) {
        const walletsorgu= { limit: '1', "query": {"walletid": hesap} }
        dispatch(fetchOrders("wallets",walletsorgu,"walletdata"));
    }
    async function updatepools() {
        const poolsorgu= { limit: 0,  "query": {status:"ACTIVE"}, "order": { sira : 1 }  }
        dispatch(fetchRows("nftstakepools",poolsorgu,"nftpool"))
    }
    const allData = useSelector((state) => state.general);
    const allpools = allData.extradata.nftpool?allData.extradata.nftpool.pool:[];
    const currencies = allData.extradata.nftpool?allData.extradata.nftpool.currencies:[];
    useEffect(() => {

        const poolsorgu= { limit: '1', "query": marketurl, "order": { sira : 1 } }
        const ethEnabled = async () => {

            const result = await axios.post(WEB_CONFIG.server+'/api/general/getrows/nftstakepools',poolsorgu);
            if(result.data) {
                setPool(result.data);
                const poolsorgu= { limit: 0, "query": {}, "order": { sira : 1 }  }
                dispatch(fetchRows("nftstakepools",poolsorgu,"pool"))
                //  await fetchDataFromBlockchain(result.data);
            }
        };
        ethEnabled();
        updatepools();
        //checkaccount();
    }, []);
    function hdifference(date1) {
        let msDiff = new Date().getTime() - new Date(date1).getTime() ;
        return Math.floor(msDiff / (1000 * 60 * 60));
    }
    function toplamlar(saat,apy,amount,p) {
        let apysaat=(apy/(365*24)*saat);
        let apygun=(parseInt(saat)/24);
        if(apygun>parseInt(p.sabit_gun)) {
            return parseFloat((amount/100)*apysaat)+parseFloat(amount);
        }
        else {
            return parseFloat(amount);
        }
    }
    function toplamlars(saat,apy) {
        return parseFloat((apy/(365*24)*saat));
    }

    const discon = () => {
        window.localStorage.removeItem('userAccount');
        setAccount(null);
        window.localStorage.clear();
    };
    const fetchDataFromBlockchain = async (pdata) => {
        if (window.ethereum) {
            const p = pdata.pool[0]?pdata.pool[0]:{};
            const c = pdata.currencies[0];
            const cc = pdata.currencies[1];
            // await window.ethereum.send('eth_requestAccounts');
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.web3 = new Web3(window.ethereum);
            //connecting to metamask
            let web3 = window.web3;
            const accounts = await web3.eth.getAccounts();
            setAccount(accounts[0]);
            updatedata(accounts[0])
            const networkId = await web3.eth.net.getId();
            if(p.network_id!=networkId) {
                alert(p.title+" Contract is not deployed on this network, please change to "+p.network_id)
            }
            const networkType = await web3.eth.net.getNetworkType();
            setNetwork({ ...network, id: networkId, name: networkType });
            //const testTokenData = TestToken.networks[networkId];
            if (pdata) {
                let web3 = window.web3;
                //c.contract_address ilk contractın adresi
                const testToken = new web3.eth.Contract(
                    TestToken.abi,
                    c.contract_address );
                setTestTokenContract(testToken);
                //  fetching balance of Testtoken and storing in state
                if(accounts[0]){
                    const mystakesorgu= { limit: '0', "query": {"wallet":accounts[0], "pool": p._id, "status":"ACTIVE"} }
                    const stakeresult = await axios.post(WEB_CONFIG.server+'/api/general/getrows/nftstakes',mystakesorgu);
                    if(stakeresult.data) {
                        setStakeresults(stakeresult.data);
                        let datamount = 0;
                        let datatoplam = 0;
                        let datatoplams = 0;
                        _(stakeresult.data).forEach(function(data) {
                            let saat=hdifference(data.start_date)
                            datamount++;
                            datatoplam +=toplamlar(saat,p.apy,data.amount,p);
                            datatoplams +=toplamlars(saat,p.apy,data.amount,p);

                        }).join(',');
                        setMystakes(datamount);
                        setMytoplam(datatoplam);
                        setMytoplams(datatoplams);
                    }
                }

            }
            else {
                setAppStatus(false);
                window.alert(
                    '1-TestToken contract is not deployed on this network, please change to testnet'
                );
            }

            if (cc) {
                let web3 = window.web3;
                const tokenStaking = new web3.eth.Contract(
                    TokenStaking.abi,
                    cc.contract_address
                );
                setTokenStakingContract(tokenStaking);
            }
            else {
                setAppStatus(false);
                window.alert(
                    '2-TokenStaking contract is not deployed on this network, please change to testnet'
                );
            }
            //removing loader
            setLoader(false);
        } else if (!window.web3) {
            setAppStatus(false);
            setAccount('Metamask is not detected');
            setLoader(false);
        }
    };
    const inputHandler = (received) => {
        setInputValue(received);
    };

    const connectHandler = () => {
        fetchDataFromBlockchain(pool);
    }
    const stakeHandler = () => {
        if (!appStatus) {
            console.log("uygun değil");
        } else {
            if (!inputValue || inputValue === '0' || inputValue < 1) {
                setInputValue('');
            } else {
                let pp=pool.pool[0];
                setLoader(true);
                testTokenContract.methods
                    .approve(tokenStakingContract._address, inputValue)
                    .send({ from: account })
                    .on('transactionHash', (hash) => {
                        //Eğer işlem başarılıysa
                        testTokenContract.methods
                            .transferFrom(account,pp.yatirma_adresi,inputValue)
                            .send({ from: account })
                            .on('transactionHash', () => {
                                const nftstartsorgu= { limit: '1', "condition":"nftstakestart", "query": {"confirmation_number":0,"pool":pp._id,"nft_id":inputValue,"hash":hash,"wallet": account,"lock_account":pp.yatirma_adresi,"amount":1,"status":"UNCORFIRMED"} }
                                axios.post(WEB_CONFIG.server+'/api/general/update/nftstakestart',nftstartsorgu);
                            })
                            .on('receipt', () => {
                                //   console.log("Receipt  ALındı");
                            })
                            .on('confirmation', (confirmationNumber, receipt) => {
                                if(confirmationNumber===1) {
                                    if(receipt.status===true) {
                                        const confirmsorgu= { limit: '1', "condition":"nftstakenow", "query": {"confirmation_number":confirmationNumber,"pool":pp._id,"nft_id":inputValue,"hash":hash,"wallet": account,"lock_account":pp.yatirma_adresi,"amount":1,"status":"ACTIVE"} }
                                        axios.post(WEB_CONFIG.server+'/api/general/update/nftstakenow',confirmsorgu);
                                        updatepools();
                                        fetchDataFromBlockchain(pool);
                                        setLoader(false);
                                    }
                                }

                            })

                            .on('error', function(error) {
                                setLoader(false);
                                alert(error.message.toString());
                                //console.log('Error Code:', error.code);           console.log(error.message);
                            });
                    })
                    .on('error', function(error) {
                        setLoader(false);
                        alert(error.message.toString());
                        //console.log('Error Code:', error.code);           console.log(error.message);
                    });
                setInputValue('');
            }
        }
    };
    const odemeCek = async () => {
        if(mytoplam>0) {
            if (!appStatus) {
                console.log("uygun değil");
            } else {
                let sons=0;
                if (sons< 0) {
                    setInputValue('');
                } else {
                    let pp=pool.pool[0];
                    setLoader(true);
                    // let unstakeson=sadecekomisyonhesapla(mytoplam,pp.cekme_komisyonu).toString();
                    let convertToWei = window.web3.utils.toWei(pp.bnb_komisyon, 'gwei');
                    window.ethereum
                        .request({
                            method: 'eth_sendTransaction',
                            params: [
                                {
                                    from: account,
                                    to: pp.cekme_adresi,
                                    value: convertToWei,

                                },
                            ],
                        })
                        .then(() => {
                            // console.log(txHash)
                            unStakeHandler();
                        })
                        .catch((error) => {
                            setLoader(false);
                            alert(error.message.toString());


                        });
                }
            }

        } else {
            alert("You don't have any stakes yet!");
        }
    };
    const stakeodemeCek = async (e) => {


        let acik =0;
        if(acik===0) {

            if (!appStatus) {
                console.log("uygun değil");
            } else {
                let sons=0;
                if (sons< 0) {
                    setInputValue('');
                } else {
                    let pp=pool.pool[0];
                    setLoader(true);
                    // console.log("pp.cekme_komisyonu"); console.log(pp.cekme_komisyonu);

                    let unstakeson="12000";
                    //    console.log("unstakeson"); console.log(unstakeson);
                    let convertToWei = window.web3.utils.toWei(pp.bnb_komisyon, 'gwei');
                    window.ethereum
                        .request({
                            method: 'eth_sendTransaction',
                            params: [
                                {
                                    from: account,
                                    to: pp.cekme_adresi,
                                    value: convertToWei,

                                },
                            ],
                        })
                        .then((txHash) => {
                            // console.log(txHash)
                            stakeHandler();
                        })
                        .catch((error) => {
                            setLoader(false);
                            alert(error.message.toString());

                        });
                }
            }

        } else {
            alert("You don't have any stakes yet!");
        }
    };
    const unStakeHandler = async () => {
        console.log("unstakehandelr aç");
        let cp = pool.pool[0];
        let ccur = pool.currencies[1];

        if (!appStatus) {
            console.log("appStatus uygun değil");  console.log(appStatus);
        } else {
            setLoader(true);
            // let convertToWei = window.web3.utils.toWei(inputValue, 'Ether')


            const Web3js = new Web3(new Web3.providers.HttpProvider('https://ancient-red-log.bsc.discover.quiknode.pro/26c10fe5617170e70368544ca324469ad65b406a'));
            const privateKey = cp.cekme_adresi_private //Your Private key environment variable
            let tokenAddress = ccur.contract_address // Demo Token contract address
            let toAddress = account // where to send it
            let fromAddress = cp.cekme_adresi // your wallet
            let contract = new Web3js.eth.Contract(TokenStaking.abi, tokenAddress, { from: fromAddress })


            //    console.log("cp");    console.log(cp);

            //burası hesaplamanın yapılıp paranın gönderileceği yer.
            //let unstakeson=cekimkomisyonhesapla(mytoplam,cp.cekme_komisyonu).toString();
            //let convertToWei = window.web3.utils.toWei(unstakeson.toString(), 'ether');
            //let amount = Web3js.utils.toHex(convertToWei); //1 DEMO Token
            let data = testTokenContract.methods.transferFrom(cp.cekme_adresi,toAddress, inputValue).encodeABI();

            const unstakesorgu= { limit: '1', "condition":"nftunstakenow", "query": {"pool":cp._id,"wallet": toAddress,"nft_id":inputValue,"stakedamount":1,"totalamount":1,"status":"UNSTAKED"} }
            const unresult = await axios.post(WEB_CONFIG.server+'/api/general/update/nftstakepools',unstakesorgu);

            if(unresult.data.status==="1") {

                let  unft=unresult.data?unresult.data.unstakenft[0]:null;


                if(unft) {

                    let saat=hdifference(unft.start_date)

                    let usdatatoplams=toplamlars(saat,cp.apy);

                    let convertToWe = window.web3.utils.toWei(usdatatoplams.toString(), 'ether');
                    let amounts = Web3js.utils.toHex(convertToWe); //1 DEMO Token
                    let datakomisyon = contract.methods.transfer(toAddress, amounts).encodeABI();
                    sendErcToken(datakomisyon,usdatatoplams)
                    updatepools();
                    fetchDataFromBlockchain(pool);
                }
                else {
                    console.log("unresult.data hatası");
                    setLoader(false);
                    alert("Error: You don't have a staked NFT with this id!");
                }



            }
            else {
                console.log("unresult.data hatası");
                setLoader(false);
                alert("Error: You don't have a staked NFT with this id!");
            }

            async function sendErcToken(datakomisyon, usdatatoplams) {
                let txCount = '0x' + (await Web3js.eth.getTransactionCount(fromAddress)).toString(16);
                let txObj = {
                    gas: Web3js.utils.toHex(400000),
                    "to": tokenAddress,
                    "value": "0x0",
                    "data": data,
                    "chainId": cp.network_id,
                    "nonce": txCount,
                    "from": fromAddress
                }
                Web3js.eth.accounts.signTransaction(txObj, privateKey, (err, signedTx) => {
                    if (err) {
                        //  console.log(err);
                        setLoader(false);
                        alert(err.message.toString());
                    } else {
                        //console.log(signedTx)
                        setLoader(true);
                        return Web3js.eth.sendSignedTransaction(signedTx.rawTransaction, (err) => {
                            if (err) {
                                console.log(err)
                                setLoader(false);
                                alert(err.message.toString());
                            } else {
                                //alert("Congratulations, your unstake has been completed. Tx Hash: "+signedTx.transactionHash)
                                if (usdatatoplams > 0) {
                                    sendKomisyon(datakomisyon);
                                } else {
                                    console.log("Yeterli Komisyon yok işlem sonlandırılıyor");
                                    unstakesonlandir();

                                }


                            }
                        })
                    }
                })
            }
            async function sendKomisyon(datakomisyon) {
                let txCount = '0x' + (await Web3js.eth.getTransactionCount(fromAddress) + 1).toString(16);
                let txObjs = {
                    gas: Web3js.utils.toHex(600000),
                    "to": cp.komisyon_kuru,
                    "value": "0x0",
                    "data": datakomisyon,
                    "nonce":txCount,
                    "chainId": cp.network_id,
                    "from": fromAddress
                }

                Web3js.eth.accounts.signTransaction(txObjs, privateKey, (err, signedTxs) => {
                    if (err) {
                        console.log(err);
                    } else {
                        //console.log(signedTx)
                        setLoader(true);
                        return Web3js.eth.sendSignedTransaction(signedTxs.rawTransaction, (err) => {
                            if (err) {
                                setLoader(false);
                                alert(err.message.toString());
                                console.log(err);

                            } else {
                                setLoader(true);
                                //alert("Congratulations, your unstake has been completed. Tx Hash: "+signedTx.transactionHash)
                                unstakesonlandir();

                            }
                        })
                    }
                })
            }


        }




    }
    const unstakesonlandir = async () => {

        updatepools();
        fetchDataFromBlockchain(pool);
        setLoader(false);

    }

    return (
        <>
            <Header market={market?market:'home'} discon={discon} account={account} connectHandler={connectHandler}/>
            {loader===true?(
                <div className={"pending"}>Pending...<p>Please Don't Leave Page</p></div>
            ):(
                <></>
            )}
            <div className="text-top">
                <h1>NFT STAKE POOL</h1>
                <p>Just stake some NFT's to earn. High APR, low risk.</p>
            </div>
            <div className="wrapper">
                {allpools?
                    allpools.map((mypool,i) => (
                        <NftStakebox
                            key={mypool._id}
                            connectHandler={connectHandler}
                            maporder={i}
                            mystakes={mystakes?mystakes:0}
                            mytoplam={mytoplam?mytoplam:0}
                            mytoplams={mytoplams?mytoplams:0}
                            account={account}
                            stakeresults={stakeresults?stakeresults:0}
                            cur={currencies}
                            market={market}
                            pooldata={mypool}
                            unStakeHandler={odemeCek}
                            stakeHandler={stakeodemeCek}
                            inputHandler={inputHandler}
                        />
                    )):<div> </div>}
            </div>
        </>
    );
};
export default Nftpools;
