import * as types from "../actions/types";

const initialState = {
    userwallets: [],
    currentUserwallets: null,
};

const userwalletsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_POSTS:
            return {
                ...state,
                userwallets: action.payload,
            };
        case types.FETCH_SINGLE_POST:
            return {
                ...state,
                currentUserwallets: action.payload,
            };
        case types.CREATE_POST:
            return {
                ...state,
                userwallets: [...state.userwallets, action.payload],
            };
        case types.UPDATE_POST:
            return {
                ...state,
                userwallets: state.userwallets.map((userwallets) => {
                    if (userwallets._id === action.payload._id) {
                        return action.payload;
                    } else {
                        return userwallets;
                    }
                }),
                currentUserwallets: action.payload,
            };
        case types.DELETE_POST:
            return {
                ...state,
                userwallets: state.userwallets.filter((userwallets) => userwallets._id !== action.payload),
                currentUserwallets: null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default userwalletsReducer;
