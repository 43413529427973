import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useParams } from 'react-router-dom';
import Web3 from 'web3';
import TestToken from '../../src/abis/Spaceshib.json';
import TokenStaking from '../../src/abis/TokenStaking.json';
import {fetchRows,fetchOrders} from "../actions/general";
import axios from "axios";
import {WEB_CONFIG} from '../config/Static'
import Header from '../components/Header';
import Stakebox from '../components/Stakebox';
import _ from "lodash";
const Metaverse = () => {
    const dispatch = useDispatch();
    const { market } = useParams();
    const [account, setAccount] = useState(null);
    const [pool, setPool] = useState({});
    const [mystakes, setMystakes] = useState({});
    const [mytoplam, setMytoplam] = useState({});
    const [mytoplams, setMytoplams] = useState({});
    const [userBalance, setUserBalance] = useState('0');
    const [network, setNetwork] = useState({ id: '0', name: 'none' });
    const [testTokenContract, setTestTokenContract] = useState('');
    const [tokenStakingContract, setTokenStakingContract] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [appStatus, setAppStatus] = useState(true);
    const [loader, setLoader] = useState(false);
    const marketurl = market?{url:market}: {};
    async function updatedata(hesap) {
        const walletsorgu= { limit: '1', "query": {"walletid": hesap} }
        dispatch(fetchOrders("wallets",walletsorgu,"walletdata"));
    }
    async function updatepools() {
        const poolsorgu= { limit: 0, "query": {}, "order": { sira : 1 }  }
        dispatch(fetchRows("stakepools",poolsorgu,"pool"))
    }
    const allData = useSelector((state) => state.general);
    const allpools = allData.extradata.pool?allData.extradata.pool.pool:[];

    //const pooldata = allData.extradata.pool?allData.extradata.pool.pool[0]:[];
    const currencies = allData.extradata.pool?allData.extradata.pool.currencies:[];

    useEffect(() => {

        const poolsorgu= { limit: '1', "query": {sayfa:"pools"}, "order": { sira : 1 } }
        const ethEnabled = async () => {
            const result = await axios.post(WEB_CONFIG.server+'/api/general/getrows/stakepools',poolsorgu);
            if(result.data) {
                setPool(result.data);
                const poolsorgu= { limit: 0, "query": {}, "order": { sira : 1 }  }
                dispatch(fetchRows("stakepools",poolsorgu,"pool"))
            }
        };
        ethEnabled();
        updatepools();
        //checkaccount();
    }, []);

    function hdifference(date1) {
        let msDiff = new Date().getTime() - new Date(date1).getTime() ;
        return Math.floor(msDiff / (1000 * 60 * 60));
    }
    function toplamlar(saat,apy,amount,p) {
        let apysaat=(apy/(365*24)*saat);
        let apygun=(parseInt(saat)/24);
        if(apygun>parseInt(p.sabit_gun)) {
            return parseFloat((amount/100)*apysaat)+parseFloat(amount);
        }
        else {
            return parseFloat(amount);
        }

    }
    function toplamlars(saat,apy,amount) {
        let apysaat=(apy/(365*24)*saat);
        //let apygun=(parseInt(saat)/24);
        return parseFloat((amount/100)*apysaat)+parseFloat(amount);

    }
    function komisyonhesapla(para,komisyon) {
        let sondeger = (parseFloat(para)/100)*parseFloat(komisyon);
        return parseFloat(sondeger)+parseFloat(para);
    }
    function cekimkomisyonhesapla(para,komisyon) {
        let sondeger = (parseFloat(para)/100)*parseFloat(komisyon);
        return parseFloat(para)-parseFloat(sondeger);
    }
    function sadecekomisyonhesapla(para,komisyon) {
        return (parseFloat(para)/100)*parseFloat(komisyon);

    }
    const discon = () => {
        window.localStorage.removeItem('userAccount');
        setAccount(null);
        window.localStorage.clear();
    };
    const fetchDataFromBlockchain = async (pdata) => {
        if (window.ethereum) {
            const p = pdata.pool[0]?pdata.pool[0]:{};
            const c = pdata.currencies[0];
            const cc = pdata.currencies[1];
            // await window.ethereum.send('eth_requestAccounts');
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.web3 = new Web3(window.ethereum);
            //connecting to metamask
            let web3 = window.web3;
            const accounts = await web3.eth.getAccounts();
            setAccount(accounts[0]);
            updatedata(accounts[0])
            //loading users network ID and name
            const networkId = await web3.eth.net.getId();

            if(p.network_id!=networkId) {
                alert(p.title+" Contract is not deployed on this network, please change to "+p.network_id)
            }
            const networkType = await web3.eth.net.getNetworkType();
            setNetwork({ ...network, id: networkId, name: networkType });
            //const testTokenData = TestToken.networks[networkId];
            if (pdata) {
                let web3 = window.web3;
                //c.contract_address ilk contractın adresi
                const testToken = new web3.eth.Contract(
                    TestToken.abi,
                    c.contract_address );
                setTestTokenContract(testToken);
                let testTokenBalance = await testToken.methods.balanceOf(accounts[0]).call();
                let convertedBalance = window.web3.utils.fromWei(
                    testTokenBalance.toString(),'ether'
                );
                if(accounts[0]){
                    const mystakesorgu= { limit: '0', "query": {"wallet":accounts[0], "pool": p._id, "status":"ACTIVE"} }
                    const stakeresult = await axios.post(WEB_CONFIG.server+'/api/general/getrows/stakes',mystakesorgu);
                    if(stakeresult.data) {
                        let datamount = 0;
                        let datatoplam = 0;
                        let datatoplams = 0;
                        _(stakeresult.data).forEach(function(data) {
                            let saat=hdifference(data.start_date)
                            datamount+=parseFloat(data.amount);
                            datatoplam +=toplamlar(saat,p.apy,data.amount,p);
                            datatoplams +=toplamlars(saat,p.apy,data.amount);

                        }).join(',');
                        setMystakes(datamount);
                        setMytoplam(datatoplam);
                        setMytoplams(datatoplams);
                    }
                }

                setUserBalance(convertedBalance);
                //fetching contract balance
                //updating total staked balance
                //  const tempBalance = TokenStaking.networks[p.network_id];
                //  let totalStaked = p.total_locked;
                //convertedBalance = p.total_locked;
                /*
                convertedBalance = window.web3.utils.fromWei(
                  totalStaked.toString(),
                  'Ether'
                );
                */

                //removing initial balance
                // setContractBalance(convertedBalance);
            }
            else {
                setAppStatus(false);
                window.alert(
                    '1-TestToken contract is not deployed on this network, please change to testnet'
                );
            }
            //loading TokenStaking contract data
            //database3

            //const tokenStakingData = 56;
            if (cc) {
                let web3 = window.web3;
                const tokenStaking = new web3.eth.Contract(
                    TokenStaking.abi,
                    cc.contract_address
                );
                setTokenStakingContract(tokenStaking);
                //  fetching total staked TokenStaking  and storing in state
                //burada kendi db içindeki stakimi çağır

                // setMyStake([convertedBalance, tempCustomdBalance]);
                //checking totalStaked
                //Burada toplam staked miktarını yansıt
                //let tempTotalStaked = await tokenStaking.methods.totalStaked().call();

                /*
                 let tempcustomTotalStaked = await tokenStaking.methods
                  .customTotalStaked()
                  .call();
                let tempconvertedBalance = window.web3.utils.fromWei(
                  tempcustomTotalStaked.toString(),
                  'Ether'
                );

                 */
                // setTotalStaked([convertedBalance, tempconvertedBalance]);
                //fetching APY values from contract

                // ((await tokenStaking.methods.defaultAPY().call()) / 1000) * 365;

                //  ((await tokenStaking.methods.customAPY().call()) / 1000) * 365;
                //setApy([tempApy, tempcustomApy]);
            }
            else {
                setAppStatus(false);
                window.alert(
                    '2- contract is not deployed on this network, please change to testnet'
                );
            }
            //removing loader
            setLoader(false);
        } else if (!window.web3) {
            setAppStatus(false);
            setAccount('Metamask is not detected');
            setLoader(false);
        }
    };
    const inputHandler = (received) => {
        setInputValue(received);
    };
    const connectHandler = (e) => {
        e.preventDefault();
        fetchDataFromBlockchain(pool);
        updatepools();
    }
    const stakeHandler = (e) => {
        e.preventDefault();
        if (!appStatus) {
            console.log("uygun değil");
        } else {
            if (!inputValue || inputValue < 1) {
                setInputValue('');
            } else {
                let pp=pool.pool[0];
                setLoader(true);
                let sondeger=komisyonhesapla(inputValue,pp.kilitleme_komisyonu).toString();
                let convertToWei = window.web3.utils.toWei(sondeger, 'ether');
                testTokenContract.methods
                    .approve(tokenStakingContract._address, convertToWei)
                    .send({ from: account })
                    .on('transactionHash', (hash) => {
                        tokenStakingContract.methods
                            .transfer(pp.yatirma_adresi,convertToWei)
                            .send({ from: account })
                            .on('transactionHash', () => {
                                const startsorgu= { limit: '1', "condition":"stakestart", "query": {"confirmation_number":0,"pool":pp._id,"hash":hash,"wallet": account,"lock_account":pp.yatirma_adresi,"amount":inputValue,"status":"UNCORFIRMED"} }
                                axios.post(WEB_CONFIG.server+'/api/general/update/receipt',startsorgu);
                            })
                            .on('receipt', () => {
                                // fetchDataFromBlockchain(pool);

                            })
                            .on('confirmation', (confirmationNumber, receipt) => {
                                // console.log("confirmation"); console.log(receipt);
                                if(confirmationNumber===1) {

                                    if(receipt.status===true) {
                                        const confirmsorgu= { limit: '1', "condition":"stakenow", "query": {"confirmation_number":confirmationNumber,"pool":pp._id,"hash":hash,"wallet": account,"lock_account":pp.yatirma_adresi,"amount":inputValue,"status":"ACTIVE"} }
                                        axios.post(WEB_CONFIG.server+'/api/general/update/stakenow',confirmsorgu);
                                        updatepools();
                                        fetchDataFromBlockchain(pool);
                                        setLoader(false)
                                    }
                                }
                                else {
                                    //console.log("çekimtamamlandı");

                                }

                            })
                            .on('error', function(error) {
                                setLoader(false);
                                alert(error.message.toString());
                            });


                    })
                    .on('error', function(error) {
                        setLoader(false);
                        alert(error.message.toString());
                    });
                setInputValue('');
            }
        }
    };
    const odemeCek = async (e) => {

        e.preventDefault();
        if(mytoplam>0) {

            if (!appStatus) {
                console.log("uygun değil");
            } else {
                let sons=0;
                if (sons< 0) {
                    setInputValue('');
                } else {
                    let pp=pool.pool[0];
                    setLoader(true);
                    // console.log("pp.cekme_komisyonu"); console.log(pp.cekme_komisyonu);

                    let unstakeson=sadecekomisyonhesapla(mytoplam,pp.cekme_komisyonu).toString();
                    //    console.log("unstakeson"); console.log(unstakeson);
                    let convertToWei = window.web3.utils.toWei(pp.bnb_komisyon, 'gwei');
                    window.ethereum
                        .request({
                            method: 'eth_sendTransaction',
                            params: [
                                {
                                    from: account,
                                    to: pp.cekme_adresi,
                                    value: convertToWei,

                                },
                            ],
                        })
                        .then((txHash) => {
                            // console.log(txHash)
                            unStakeHandler();
                        })
                        .catch((error) => {
                            setLoader(false);
                            alert(error.message.toString());

                        });
                }
            }

        } else {
            alert("You don't have any stakes yet!");
        }
    };
    const stakeodemeCek = async (e) => {

        e.preventDefault();
        let acik =0;
        if(acik===0) {

            if (!appStatus) {
                console.log("uygun değil");
            } else {
                let sons=0;
                if (sons< 0) {
                    setInputValue('');
                } else {
                    let pp=pool.pool[0];
                    setLoader(true);
                    // console.log("pp.cekme_komisyonu"); console.log(pp.cekme_komisyonu);

                    let unstakeson="12000";
                    //    console.log("unstakeson"); console.log(unstakeson);
                    let convertToWei = window.web3.utils.toWei(pp.bnb_komisyon, 'gwei');
                    window.ethereum
                        .request({
                            method: 'eth_sendTransaction',
                            params: [
                                {
                                    from: account,
                                    to: pp.cekme_adresi,
                                    value: convertToWei,

                                },
                            ],
                        })
                        .then((txHash) => {
                            // console.log(txHash)
                            stakeHandler(e);
                        })
                        .catch((error) => {
                            setLoader(false);
                            alert(error.message.toString());

                        });
                }
            }

        } else {
            alert("You don't have any stakes yet!");
        }
    };
    const unStakeHandler = async () => {
        let cp = pool.pool[0];
        let ccur = pool.currencies[1];
        if (!appStatus) {
            //   console.log("appStatus uygun değil");  console.log(appStatus);
        } else {
            setLoader(true);
            // let convertToWei = window.web3.utils.toWei(inputValue, 'Ether')
            const Web3js = new Web3(new Web3.providers.HttpProvider('https://ancient-red-log.bsc.discover.quiknode.pro/26c10fe5617170e70368544ca324469ad65b406a'));
            const privateKey = cp.cekme_adresi_private //Your Private key environment variable
            let tokenAddress = ccur.contract_address // Demo Token contract address
            let toAddress = account // where to send it
            let fromAddress = cp.cekme_adresi // your wallet
            let contract = new Web3js.eth.Contract(TokenStaking.abi, tokenAddress, { from: fromAddress })
            // console.log("fromAddress");    console.log(fromAddress);
            //burası hesaplamanın yapılıp paranın gönderileceği yer.
            let unstakeson=cekimkomisyonhesapla(mytoplam,cp.cekme_komisyonu).toString();
            let convertToWei = window.web3.utils.toWei(unstakeson.toString(), 'ether');
            // console.log("unst convertToWei");   console.log(convertToWei);
            let amount = Web3js.utils.toHex(convertToWei); //1 DEMO Token
            let data = contract.methods.transfer(toAddress, amount).encodeABI();
            const unstakesorgu= { limit: '1', "condition":"unstakenow", "query": {"pool":cp._id,"wallet": toAddress,"stakedamount":mystakes,"totalamount":mytoplam,"status":"UNSTAKED"} }
            const unresult = await axios.post(WEB_CONFIG.server+'/api/general/update/unstakenow',unstakesorgu);
            if(unresult.data) {
                // console.log("unresult.data");   console.log(unresult.data);
                sendErcToken()
                updatepools();
                fetchDataFromBlockchain(pool);
            }
            else {
                //   console.log("unresult.data hatası");  console.log(unresult.data);
            }

            function sendErcToken() {
                let txObj = {
                    gas: Web3js.utils.toHex(400000),
                    "to": tokenAddress,
                    "value": "0x0",
                    "data": data,
                    "chainId": cp.network_id,
                    "from": fromAddress
                }
                Web3js.eth.accounts.signTransaction(txObj, privateKey, (err, signedTx) => {
                    if (err) {
                        //  console.log(err);
                    } else {
                        //console.log(signedTx)
                        setLoader(true);
                        return Web3js.eth.sendSignedTransaction(signedTx.rawTransaction, (err) => {
                            if (err) {
                                // console.log(err)
                            } else {
                                //alert("Congratulations, your unstake has been completed. Tx Hash: "+signedTx.transactionHash)
                                unstakesonlandir();

                            }
                        })
                    }
                })
            }


        }




    }
    const unstakesonlandir = async () => {
        updatepools();
        fetchDataFromBlockchain(pool);
        setLoader(false);
    }

    return (
        <>
            <Header discon={discon} account={account} connectHandler={connectHandler}/>
            {loader===true?(
                <div className={"pending"}>Pending...<p>Please Don't Leave Page</p></div>
            ):(
                <></>
            )}
            <div className="text-top">
                <h1>SYRUP POOL</h1>
                <p>Just stake some tokens to earn. High APR, low risk.</p>
            </div>
            <div className="wrapper">
                {allpools?
                    allpools.map((mypool,i) => (
                        <Stakebox
                            key={mypool._id}
                            connectHandler={connectHandler}
                            maporder={i}
                            mystakes={mystakes?mystakes:0}
                            mytoplam={mytoplam?mytoplam:0}
                            mytoplams={mytoplams?mytoplams:0}
                            account={account}
                            userBalance={userBalance}
                            cur={currencies}
                            market={market}
                            pooldata={mypool}
                            unStakeHandler={odemeCek}
                            stakeHandler={stakeodemeCek}
                            inputHandler={inputHandler}
                        />
                    )):<div> </div>}


            </div>
        </>
    );
};

export default Metaverse;
