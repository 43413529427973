import Header from "../components/Header";
import React from "react";

const Farms = () => {



    return (
            <>
            <Header />
                <div className="text-top">
                    <h1>FARMS</h1>
                    <p>Stake LP tokens to earn. Coming Soon!</p>
                </div>

            </>
            );
}
export default Farms;