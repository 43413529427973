import Header from "../components/Header";
import React from "react";

const Swap = () => {



    return (
        <>
            <Header />
            <div className="text-top">
                <h1>SWAP</h1>
                <p>Coming Soon!</p>
            </div>
        </>
    );
}
export default Swap;