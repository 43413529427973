import React, {useEffect, useState} from 'react';
const Header = (props) => {
    let market = window.location.pathname;
    const [ust, setUst] = useState("goster");
    const [alt, setAlt] = useState("gizle");
    const [active, setActive] = useState("0");
    useEffect(() => {
        const activate = () => {

            if(market.includes("/pools")) {
                setActive("pools");
            }
            else if(market.includes("/meta")) {
                setActive("meta");
            }
            else if(market.includes("/nftpools")) {
                setActive("nftpools");
            }
            else if(market.includes("/swap")) {
                setActive("swap");
            }
            else if(market.includes("/farms")) {
                setActive("farms");
            }
            else {
                setActive("home");
            }
            /*
            switch (market) {
                case "/" : setActive("home"); break;
                case market.includes("pools") : setActive("pools"); break;
                case "/nftpools" : setActive("nftpools"); break;
                case "/swap" : setActive("swap"); break;
                case "/farms" : setActive("farms"); break;
                default: setActive("home");break;

            }
             */

        };
        activate();
    }, []);
    const changeText = () => {
        setUst("gizle");
        setAlt("goster")
    }
    const changeTextLeft = () => {
        setUst("goster");
        setAlt("gizle")
    }

   // console.log("active");   console.log(active);
    return (

        <div>
            <header>
                <div className="menu-click"><img src="/img/menu-btn.svg" className="passive-btn" alt="" /> <img src="/img/passive-btn.svg" className="active-btn" alt="" /></div>
                <a href="#" className="logo"><img src="/img/logo.svg" alt="" /></a>
                {props.account?(
                        <div className="btn-right">
                            <a onMouseLeave={changeTextLeft} onMouseEnter={()=>{changeText()}} href="#" onClick={props.discon}><img src="/img/btn-bg.svg" alt="" />
                                <span  className={"mouseover "+ust}> {props.account.substring(0,2)}...{props.account.substring(props.account.length,(props.account.length-4))}</span>
                                <span  className={"mouseover "+alt}> Disconnect Wallet</span>
                            </a>

                        </div>
                    ):
                    <div className="btn-right">
                        <a href="#" onClick={props.connectHandler}><img src="/img/btn-bg.svg" alt="" />
                            <span>{"Connect Wallet"}</span></a>
                    </div>
                }
            </header>
            <div className="left-menu">
                <div className="left-ul">
                    <a className={active==="home"?"active activeReal":null}  href="/"><img className={"m_image"} src="/img/home.svg" alt="" /> <span>Home</span></a>
                    <a className={active==="pools"?"active activeReal":null} href="/pools"><img className={"m_image"} src="/img/pool.svg" alt="" /> <span>$Megaland Pools</span></a>
                    <a className={active==="meta"?"active activeReal":null} href="/meta"><img className={"m_image str"} src="/img/star.png" alt="" /> <span>$Metaverse Staking</span></a>
                    <a className={active==="nftpools"?"active activeReal":null} href="/nftpools"><img className={"m_image"} src="/img/saturn.png" alt="" /> <span>NFT Pools</span></a>
                    <a className={active==="swap"?"active activeReal":null} href="/swap"><img className={"trimg m_image"} src="/img/trade.svg" alt="" /> <span>Trade</span></a>
                    <a className={active==="farms"?"active activeReal":null} href="/farms"><img className={"m_image"} src="/img/farm.svg" alt="" /> <span>Farms</span></a>
                </div>
                <div className="jwVXio" />
                <div className="link-send">
                    <a href="https://metagalaxyland.com/">
                        <span className={"m_span"}>&lt; Go to Main Website</span>
                    </a>
                </div>
                <div className="social-bottom">
                    <a href="#" className="mail">info@metagalaxyland.com</a>
                    <div className="icons">
                        <a href="mailto:info@metagalaxyland.com"><img src="/img/message.svg" alt="" /></a>
                        <a href="https://twitter.com/metagalaxyland" target="_blank"><img src="/img/twitter.svg" alt="" /></a>
                        <a href="https://discord.com/invite/jwpbk9k9Ws" target="_blank"><img src="/img/discord.svg" alt="" /></a>
                        <a href="https://t.me/metagalaxylandofficial" target="_blank"><img src="/img/telegram.svg" alt="" /></a>
                        <a href="https://medium.com/@metagalaxyland" target="_blank"><img src="/img/icon4.svg" alt="" /></a>
                        <a href="https://github.com/MetaGalaxy-Land" target="_blank"><img src="/img/git.svg" alt="" /></a>
                        <a href="https://www.instagram.com/metagalaxyland/" target="_blank"><img src="/img/instagram.svg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Header;
