import React, { useState, useEffect } from 'react';
import axios from "axios";
import {WEB_CONFIG} from '../config/Static'
import Header from '../components/Header';
const Home = () => {
    const [cmcdata, setCmcdata] = useState({});
    const [account, setAccount] = useState('');
    const [pops, setPops] = useState(true);
    useEffect(() => {
        const ethEnabled = async () => {
            const cmc = await axios.get(WEB_CONFIG.server+'/api/general/getcmc');
            if(cmc.data) {
                setCmcdata(cmc.data)
            }
        };
        ethEnabled();

    }, []);
    const connectHandler = () => {
      window.ethereum.request({method:'eth_requestAccounts'})
.then(res=>{
      // Return the address of the wallet
      setAccount(res[0]);

})
    }

    return (
        <>
            <Header discon={null} account={account} connectHandler={connectHandler}/>
            {pops===true && (
              <div id="fix" className="fixbg" >
               <div className="fixpop" >
                 <div  className="cls"><a onClick={() => {
                    setPops(false)
                 }} href="#">X</a></div>
                 
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="56" height="56" rx="28" fill="#15edbd" fill-opacity="0.1"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M41.3334 28.0003C41.3334 35.3337 35.3334 41.3337 28.0001 41.3337C20.6667 41.3337 14.6667 35.3337 14.6667 28.0003C14.6667 20.667 20.6667 14.667 28.0001 14.667C35.3334 14.667 41.3334 20.667 41.3334 28.0003ZM27.9974 34.667C27.261 34.667 26.6641 34.07 26.6641 33.3337L26.6641 26.667C26.6641 25.9306 27.261 25.3337 27.9974 25.3337C28.7338 25.3337 29.3307 25.9306 29.3307 26.667L29.3307 33.3337C29.3307 34.07 28.7338 34.667 27.9974 34.667ZM29.3381 22.667C29.3381 23.4034 28.7411 24.0003 28.0047 24.0003H27.9928C27.2564 24.0003 26.6594 23.4034 26.6594 22.667C26.6594 21.9306 27.2564 21.3337 27.9928 21.3337H28.0047C28.7411 21.3337 29.3381 21.9306 29.3381 22.667Z" fill="#15edbd"/>
                </svg>
                 <br/><br/>
                 Our DEX platform is only compatible with Metamask wallet. Please do not use other wallets. 
                <br/>
                <span styleb={{fontSize: 11}}>Our project is not liable for any issues that arise as a result of using different wallets.</span>

                {/* <a target="_blank" href="https://dex.metagalaxyland.com/meta"><img src="https://metagalaxyland.com/upload/images/stakes.png" /></a> */}
               </div>
             </div>
            )}


            <div className="contentHome">
                <div className="text-top mt-0 wow fadeInUp" data-wow-delay="0.5s">
                    <h1>metagalatic <br />swap</h1>
                </div>
                <div className="row-expands">
                    <div className="left wow fadeInUp">
                        <h3>
                            MEGALAND EXPANDS THE METAVERSE TO A WHOLE NEW LEVEL!
                        </h3>
                        <p>
                            MEGALAND token is at the core of the Metagalaxy Land ecosystem. Buy it, win it, farm it, spend it, stake it.
                        </p>
                        <div className="btns">
                            <div className="btn-buy">
                                <a href="#" className="btn-colmn"><img src="img/btn-bg.svg" alt="" /> <span>Buy MEGALAND</span></a>
                                <div className="dropBuy">
                                    <a href="https://www.mexc.com/exchange/MEGALAND_USDT">on MEXC</a>
                                    <a href="https://pancakeswap.finance/swap?outputCurrency=0x7cd8c22d3f4b66230f73d7ffcb48576233c3fe33">on Pancakeswap </a>
                                </div>
                            </div>
                            <a target="_blank" href="https://www.youtube.com/playlist?list=PLxuFpKjfPU-8BNUrXzLlkO3qCmIIN_bFK">How to Stake?</a>
                        </div>
                    </div>
                    <div className="right wow fadeInUp">
                        <img src="img/swap-img-1.png" alt="" />
                    </div>
                </div>
                <div className="rowResult">
                    <div className="rowItems">
                        <div className="colItem">
                            <span>Circulating Supply</span>
                            <small>{cmcdata?cmcdata.self_reported_circulating_supply:"-"} </small>
                        </div>
                        <div className="colItem">
                            <span>Total Supply</span>
                            <small>{cmcdata?cmcdata.total_supply:"-"} </small>
                        </div>
                        <div className="colItem">
                            <span>Max Supply</span>
                            <small>{cmcdata?cmcdata.max_supply:"-"}</small>
                        </div>
                        <div className="colItem">
                            <span>Market Cap</span>
                            <small>{cmcdata?cmcdata.self_reported_market_cap:"-"} </small>
                        </div>

                    </div>

                </div>
                <div className="megalandStats" style={{display: 'none'}}>
                    <div className="colm">
                        <div className="in">
                            <h4>MEGALAND STATS</h4>
                            <div className="row1">
                                <div className="col-md">
                                    <span>Total Megaland Supply </span>
                                </div>
                                <div className="col-md">
                                    <small>150,995,779 </small>
                                </div>
                            </div>
                            <div className="row1">
                                <div className="col-md">
                                    <span>Total Megaland Supply </span>
                                </div>
                                <div className="col-md">
                                    <small>0</small>
                                </div>
                            </div>
                            <div className="row1">
                                <div className="col-md">
                                    <span>Total Megaland Supply </span>
                                </div>
                                <div className="col-md">
                                    <small>12</small>
                                </div>
                            </div>
                        </div>
                        <img src="img/stats-2.svg" alt="" />
                    </div>
                    <div className="colm">
                        <div className="in">
                            <div className="row2">
                                <h4>total value locked (tvL)</h4>
                                <div className="bigTitle">$2,994,224,835 </div>
                                <p className="text">
                                    Across all internal and external LPs and
                                    <br />
                                    Syrup Pools
                                </p>
                            </div>
                        </div>
                        <img src="img/stats-1.svg" alt="" />
                    </div>
                </div>
                <div className="row-expands mb-5">
                    <div className="left">
                        <h3>
                            TO THE TOP WITH METAGALACTIC NFTS!
                        </h3>
                        <p>
                            Trade, earn and win using NFTs, not only $MEGALAND, on the most unique metagalactic platform in the metaverse.
                        </p>
                        <div className="btns">
                            <a href="https://nft.metagalaxyland.com/" className="btn-colmn"><img src="img/btn-bg.svg" alt="" /> <span>MINT AN NFT </span></a>
                        </div>
                    </div>
                    <div className="right imgRight">
                        <img src="img/world-2.png" alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Home;
