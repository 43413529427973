import React from 'react';
import { Switch, Route  } from 'react-router-dom';
import Home from "./home";
import Farms from "./farms";
import Swap from "./swap";
import Meta from "./Meta";
import Pools from "./Pools";
import Metaverse from "./Metaverse";

import Nftpools from "./Nftpools";
const Index = () => {
    return (
        <>
                <Switch>

                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/metaverse/">
                        <Metaverse />
                    </Route>
                    <Route exact path="/swap/">
                        <Swap />
                    </Route>
                    <Route exact path="/meta/">
                        <Meta />
                    </Route>
                    <Route exact path="/farms/">
                        <Farms />
                    </Route>
                    <Route exact path="/pools/">
                        <Pools />
                    </Route>


                    <Route exact path="/nftpools/">
                        <Nftpools />
                    </Route>
                    <Route  path="/pools/:market/">
                        <Pools />
                    </Route>
                </Switch>
        </>
    );
}


export default Index;
