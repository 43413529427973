export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_SINGLE_POST = "FETCH_SINGLE_POST";
export const CREATE_POST = "CREATE_POST";
export const UPDATE_POST = "UPDATE_POST";
export const DELETE_POST = "DELETE_POST";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_BYFIELD = "FETCH_ORDERS_BYFIELD";
export const FETCH_ORDERS_BYFIELD_BUY = "FETCH_ORDERS_BYFIELD_BUY";
export const FETCH_ORDERS_BYFIELD_SELL = "FETCH_ORDERS_BYFIELD_SELL";
export const FETCH_SINGLE_ORDER = "FETCH_SINGLE_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_BOTORDER = "CREATE_BOTORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const FETCH_USERS_BYFIELD = "FETCH_USERS_BYFIELD";
export const FETCH_USERS = "FETCH_USERS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";


export const UPDATE = "UPDATE";
export const UPDATE_DATAS = "UPDATE_DATAS";
export const DELETE = "DELETE";
export const CREATE = "CREATE";
export const LIST = "LIST";
export const LIST_ALL = "LIST_ALL";
export const LIST_ORDERS = "LIST_ORDERS";
export const LIST_DATA = "LIST_DATA";
export const LIST_USERS = "LIST_USERS";
export const USER_DATA = "USER_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const DELETE_GENERAL = "DELETE_GENERAL";
export const BOT_SORGU = "BOT_SORGU";