import * as types from "../actions/types";

const initialState = {
    coins: [],
    currentCoin: null,
};

const coinReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_POSTS:
            return {
                ...state,
                coins: action.payload,
            };
        case types.FETCH_SINGLE_POST:
            return {
                ...state,
                currentCoin: action.payload,
            };
        case types.CREATE_POST:
            return {
                ...state,
                coins: [...state.coins, action.payload],
            };
        case types.UPDATE_POST:
            return {
                ...state,
                coins: state.coins.map((coin) => {
                    if (coin._id === action.payload._id) {
                        return action.payload;
                    } else {
                        return coin;
                    }
                }),
                currentCoin: action.payload,
            };
        case types.DELETE_POST:
            return {
                ...state,
                coins: state.coins.filter((coin) => coin._id !== action.payload),
                currentCoin: null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default coinReducer;
