import React, {useState} from 'react';
const NftStakebox = (props) => {
    const [slide, setSlide] = useState('');
    const p=props.pooldata?props.pooldata:[];
    const maporder=props.maporder?props.maporder:[];
    const market=props.market?props.market:0;
    function cevir(deger) {
        if(deger && deger>0)
        {            return deger;        }        else {            return 0;        }
    }
    //Buraya Ekle
    const mys=parseFloat(cevir(props.mystakes));
    const myt=parseFloat(cevir(props.mytoplams).toString());
    let rew = (myt-mys).toFixed(5);
    if(rew<0) { rew=0; }
    const inputChangeHandler = (event) => {
        event.preventDefault();
        setSlide(event.target.value);
        props.inputHandler(event.target.value);
    };

    function paralar () {
        if(market===0  || market===p.url) {
            if(maporder<1) {
                return mys+" "+p.yatirilan_para;
            }
            else {
                return 0+" "+p.yatirilan_para;
            }

        }
        else {
            return 0+" "+p.yatirilan_para;
        }
    }
    function rewardlar () {
        if(market===0 && maporder<1 || market===p.url) {
            return rew+" "+p.cekilen_para_adi;
        }
        else {
            return 0+" "+p.cekilen_para_adi;
        }
    }
    function addAmount(nftid) {

        setSlide(nftid);
        props.inputHandler(nftid);
    }

    return (
        <>
            <section className={"content-center active "}>
                <div className="top-left-text">
                    <h2>{p.title}</h2>
                    <p className={"nftp"}>Hourly Token Reward</p>
                </div>
                <img src="/img/logo-icon.svg" className="logo-icon" alt="" />
                <div className="data-top">
                    <div className="container-fluid">
                        <div className="mt-20 row">
                            <br/>
                            <div className="detail col-lg-12">
                                Stake <span> {p.yatirilan_para} </span>
                            </div>
                            <div className="col-lg-5 p5 ">
                                Total Locked:
                            </div>
                            <div className="col-lg-7 p0 text-right">
                                {cevir(p.total_locked)} {p.yatirilan_para}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nftbottom data-bottom">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 ">
                                My Stakes:
                            </div>
                            <div className="col-lg-6 text-right">
                                {paralar()}
                            </div>
                            <div className="col-lg-6 ">
                                My Rewards:
                            </div>
                            <div className="col-lg-6 text-right">
                                {rewardlar()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="links">
                    <a target="_blank" href="https://coinmarketcap.com/currencies/metagalaxy-land/
"><span> See Token Info </span> <img src="/img/link.svg" alt="" /></a>
                    <a href="https://bscscan.com/token/0x7CD8c22D3F4b66230F73D7FfCB48576233c3fe33" target="_blank"><span> View Contract </span> <img src="/img/link.svg" alt="" /></a>
                </div>

                {(market===0 && maporder<1)?(
                        props.account?(
                            <>
                                <div className="startEarnings">
                                    <div className="row startEarning bot37">
                                        <div className={"col-md-12"}>
                                            <input
                                                className="stake-input"
                                                placeholder={"Your NFT Id For Stake"}
                                                type="number"
                                                value={slide}
                                                onChange={inputChangeHandler}
                                            />
                            <div className={"row kutular"}>
                                <div className={"stinfo"}>Your Staked NFT's</div>
                                {   props.stakeresults.length>0 ?
                                props.stakeresults.map((res) => (
                                    <div key={res.nft_id} className={"p0 col-sm-3 col-md-3"}>
                                        <button onClick={() =>addAmount(res.nft_id)} className={"btn btn-info"}>NFT: #{res.nft_id}</button>
                                    </div>
                                )):  <></>
                                }


                            </div>


                                        </div>
                                        <br/>

                                        <div className={"row rbuttons"}>
                                            <div className={"col-sm-6 col-md-6"}>
                                                <a  onClick={() => {
                                                    props.stakeHandler();

                                                }} href="#"><img src="/img/btn-bg2.svg" alt="" /><span>STAKE</span>
                                                </a>
                                            </div>
                                            <div className={"col-sm-6 col-md-6"}>
                                                <a onClick={props.unStakeHandler} href="#">
                                                    <img src="/img/btn-bg2.svg" alt="" /><span>UNSTAKE NFT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ):(
                            <>
                                <div className="startEarnings">
                                    <div className="row startEarning">
                                        <a onClick={props.connectHandler} href="#">
                                            <img src="/img/btn-bg2.svg" alt="" /><span>CONNECT WALLET</span>
                                        </a>
                                    </div>
                                </div>
                            </>
                        )
                    ):
                    (
                        (market===p.url)?(
                            props.account?(
                                <>
                                    <div className="startEarnings">
                                        <div className="row startEarning">
                                            <div className={"col-md-12"}>
                                                <input
                                                    className="stake-input"
                                                    type="number"
                                                    placeholder={"Your NFT Id For Stake"}
                                                    value={slide}
                                                    onChange={inputChangeHandler}
                                                />
                                                <div className={"row kutular"}>
                                                    <div className={"p0 col-md-3"}>
                                                        <button onClick={() =>addAmount(25)} className={"btn btn-info"}>25%</button>
                                                    </div>
                                                    <div className={"p0 col-md-3"}>
                                                        <button onClick={() =>addAmount(50)} className={"btn btn-info"}>50%</button>
                                                    </div>
                                                    <div className={"p0 col-md-3"}>
                                                        <button onClick={() =>addAmount(75)} className={"btn btn-info"}>75%</button>
                                                    </div>
                                                    <div className={"p0 col-md-3"}>
                                                        <button onClick={() =>addAmount(100)} className={"btn btn-info"}>100%</button>
                                                    </div>
                                                </div>


                                            </div>
                                            <br/>

                                            <div className={"row rbuttons"}>
                                                <div className={"col-md-6"}>
                                                    <a  onClick={() => {
                                                        props.stakeHandler();

                                                    }} href="#"><img src="/img/btn-bg2.svg" alt="" /><span>STAKE</span>
                                                    </a>
                                                </div>
                                                <div className={"col-md-6"}>
                                                    <a onClick={props.unStakeHandler} href="#">
                                                        <img src="/img/btn-bg2.svg" alt="" /><span>UNSTAKE NFT</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className="startEarnings">
                                        <div className="row startEarning">
                                            <a onClick={props.connectHandler} href="#">
                                                <img src="/img/btn-bg2.svg" alt="" /><span>CONNECT WALLET</span>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )
                        ):(
                            <div className="row startEarning">
                                <div className={"m_auto"}>
                                    <a href={"/pools/"+p.url}><img src="/img/btn-bg2.svg" alt="" /><span>ENABLE</span>
                                    </a>
                                </div>
                            </div>
                        )

                    )

                }








            </section>

        </>


    );
}
export default NftStakebox;
