import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Index from './pages';
export default class App extends Component {

  render() {
    return (
        <>
          <BrowserRouter>
              <Route />
              <Index />
          </BrowserRouter>
        </>
    );
  }
}