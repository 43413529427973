import * as types from "../actions/types";

const initialState = {
    news: [],
    currentNews: null,
};

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_POSTS:
            return {
                ...state,
                news: action.payload,
            };
        case types.FETCH_SINGLE_POST:
            return {
                ...state,
                currentNews: action.payload,
            };
        case types.CREATE_POST:
            return {
                ...state,
                news: [...state.news, action.payload],
            };
        case types.UPDATE_POST:
            return {
                ...state,
                news: state.news.map((news) => {
                    if (news._id === action.payload._id) {
                        return action.payload;
                    } else {
                        return news;
                    }
                }),
                currentNews: action.payload,
            };
        case types.DELETE_POST:
            return {
                ...state,
                news: state.news.filter((news) => news._id !== action.payload),
                currentNews: null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default newsReducer;
